import React, { useCallback, useMemo } from "react"
import { Group } from "react-konva"
import { filter, random } from "lodash"
import Bubble from "./Bubble"
import { useEffectOnce, useInterval } from "react-use"
import { useAnswersState } from "../../../state/exercise/$answers"

const BubbleAnswers = ({ answers, answerChosen, answerAppeared, floatDuration }) => {
  const { updateAnswer } = useAnswersState()
  const activeAnswers = useMemo(() => filter(answers, { active: true }), [answers])
  const inactiveAnswers = useMemo(
    () => filter(answers, { active: false, answered: false, shown: false }),
    [answers]
  )
  const allInactiveAnswers = useMemo(() => filter(answers, { active: false, answered: false }), [
    answers,
  ])

  useEffectOnce(() => {
    nextBubble()
  })

  const nextBubble = useCallback(() => {
    const availableAnswers = inactiveAnswers.length
    const allAvailableAnswers = allInactiveAnswers.length
    let newAnswer = null

    if (availableAnswers > 0) {
      newAnswer = inactiveAnswers[random(availableAnswers - 1)]
    } else if (allAvailableAnswers > 0) {
      newAnswer = allInactiveAnswers[random(allAvailableAnswers - 1)]
      for (const answer of answers) {
        updateAnswer(answer.id, {
          shown: answer.id === newAnswer.id,
        })
      }
    }

    if (newAnswer != null) {
      answerAppeared(newAnswer.id)
      updateAnswer(newAnswer.id, {
        active: true,
        shown: true,
        startX: random(0, 1, true),
        endX: random(0, 1, true),
      })
    }
  }, [answers, inactiveAnswers, allInactiveAnswers, updateAnswer, answerAppeared])

  const bubbleFinished = useCallback(
    (answer) => {
      updateAnswer(answer.id, { active: false })
    },
    [updateAnswer]
  )

  useInterval(nextBubble, (floatDuration / 3) * 1000)

  return (
    <Group id="BubbleAnswers">
      {activeAnswers.map((answer) => (
        <Bubble
          key={answer.id}
          answer={answer}
          onFinish={() => bubbleFinished(answer)}
          answerChosen={() => answerChosen(answer)}
          startX={answer.startX}
          endX={answer.endX}
          floatDuration={floatDuration}
        />
      ))}
    </Group>
  )
}

export default BubbleAnswers
