import React, { useCallback, useEffect, useRef } from "react"
import { Group, Image } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import ButtonK from "../../../@konva/ui/ButtonK"
import { CARD_MARGIN, CARD_SIZE } from "."
import Konva from "konva"
import { CARD_SUITS, cardFlickSound } from "../ConversationsExercise"
import useImage from "use-image"
import { useEffectOnce } from "react-use"

const CARDS_DISTANCE = [0.1, 0.12]

const ConversationChoice = ({ position, choiceCount, content, chosen, onClick, onDragStart }) => {
  const enterAnimationR = useRef()
  const nodeR = useRef()
  const startPositionR = useRef(choiceCount - position)
  const choiceR = useRef()
  const animationR = useRef()
  const timeoutR = useRef()
  const [{ x: startX, y: startY }] = usePositions([
    {
      x: [-1.1 * CARD_SIZE.x[0], -1.1 * CARD_SIZE.x[1]],
      y: [0.5 - CARD_SIZE.y[0] / 2, 0.5 - CARD_SIZE.y[1] / 2],
    },
  ])
  const [{ x, y }, { x: width, y: height }, { x: margin }] = usePositions([
    {
      x: [
        0.5 -
          (choiceCount - 1) * CARDS_DISTANCE[0] -
          CARD_SIZE.x[0] / 2 +
          startPositionR.current * CARDS_DISTANCE[0],
        0.5 -
          (choiceCount - 1) * CARDS_DISTANCE[1] -
          CARD_SIZE.x[1] / 2 +
          startPositionR.current * CARDS_DISTANCE[1],
      ],
      y: [0.55, 0.37],
    },

    CARD_SIZE,
    CARD_MARGIN,
  ])
  const [{ x: imgWidth, y: imgHeight }] = usePositions([
    {
      x: [CARD_SIZE.x[0] / 7, CARD_SIZE.x[1] / 7],
      y: [(CARD_SIZE.y[0] / 7) * 1.6, (CARD_SIZE.y[1] / 7) * 1.6],
    },
  ])

  const [cardSuitImg] = useImage(CARD_SUITS[startPositionR.current % 4])

  useEffectOnce(() => {
    const duration = 0.3
    if (enterAnimationR.current) {
      enterAnimationR.current.reset()
    }

    timeoutR.current = setTimeout(() => {
      enterAnimationR.current = new Konva.Tween({
        node: nodeR.current,
        duration,

        x: x + width / 2,
        y: y + height / 2,
        rotation: 0,

        easing: Konva.Easings.EaseIn,
      })

      cardFlickSound.play()
      enterAnimationR.current.play()
    }, (position + 1) * 300)

    return () => {
      clearTimeout(timeoutR.current)
    }
  })

  const lift = useCallback((up = false) => {
    if (animationR.current) animationR.current.pause()

    animationR.current = new Konva.Tween({
      node: choiceR.current,
      duration: up ? 0.25 : 0.5,

      scaleX: up ? 1.15 : 1,
      scaleY: up ? 1.15 : 1,

      easing: up ? Konva.Easings.EaseIn : Konva.Easings.BounceEaseOut,
    })
    animationR.current.play()
  }, [])

  useEffect(() => {
    if (chosen) {
      if (animationR.current) animationR.current.pause()

      animationR.current = new Konva.Tween({
        node: choiceR.current,
        duration: 0.3,

        scaleX: 0.5,
        scaleY: 0.5,
        opacity: 0,

        easing: Konva.Easings.EaseIn,
      })
      animationR.current.play()
    }
  }, [chosen])

  return (
    <Group
      x={startX + width / 2}
      y={startY + height / 2}
      offsetX={width / 2}
      offsetY={height / 2}
      id="ConversationStarter"
      ref={(n) => (nodeR.current = n)}
    >
      <ButtonK
        innerRef={choiceR}
        width={width}
        height={height}
        margin={margin}
        rectProps={{ cornerRadius: 0.1 * height, stroke: COLORS.WHITE, strokeWidth: 0.03 * height }}
        text={content}
        size={"sm"}
        draggable={true}
        onClick={onClick}
        onDragStart={() => {
          lift(true)
          onDragStart()
        }}
        onDragEnd={() => {
          lift(false)
        }}
        background={
          <Group>
            <Image
              image={cardSuitImg}
              x={width - imgWidth / 2 - margin}
              y={imgHeight / 2 + margin}
              offsetX={imgWidth / 2}
              offsetY={imgHeight / 2}
              width={imgWidth}
              height={imgHeight}
              rotation={90}
              opacity={0.3}
            />
            <Image
              image={cardSuitImg}
              x={imgWidth / 2 + margin}
              y={height - imgHeight / 2 - margin}
              offsetX={imgWidth / 2}
              offsetY={imgHeight / 2}
              width={imgWidth}
              height={imgHeight}
              rotation={90}
              opacity={0.3}
            />
          </Group>
        }
      />
    </Group>
  )
}

export default ConversationChoice
