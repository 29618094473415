import React, { useRef, useState } from "react"
import clsx from "clsx"
import PlayArea from "../../lib/PlayArea"
import SprintArea from "../../app/subcomponents/SprintArea"
import CONFIG from "../../config"
import { SprintCookieConsent } from "../../app/Sprint"
import axios from "axios"
import { useEffectOnce } from "react-use"
import Card from "../Card/Card"

import "./ProjectResults.scss"

const CHECKED_INSTANCE = 76

export default function ProjectResults() {
  const [percentile, setPercentile] = useState(-1)
  const tokenRef = useRef(window.localStorage.getItem("token"))
  useEffectOnce(() => {
    if (tokenRef.current) {
      axios({
        method: "POST",
        url: CONFIG.SERVER_ADDRESS + `result/percentile/${CHECKED_INSTANCE}`,
        data: {
          token: tokenRef.current,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((result) => {
          setPercentile(result.data["percentile"])
        })
        .catch((error) => {
          setPercentile(0)
        })
    }
  })

  let text = <h1>Wczytuję...</h1>

  if (percentile > 80) {
    text = (
      <div>
        <h1>Gratulacje!</h1>
        <p>
          Twój wynik jest lepszy lub równy wynikowi {percentile}% uczestników. Wygrałeś/aś nagrodę!
        </p>
        <p>
          Napisz maila na adres <a href="mailto:test@test.pl">test@test.pl</a> aby dowiedzieć się
          jak odebrać swoją nagrodę.
        </p>
      </div>
    )
  } else if (percentile >= 50) {
    text = (
      <div>
        <h1>Bardzo dobrze!</h1>
        <p>
          Twój wynik jest lepszy lub równy wynikowi {percentile}% uczestników. Niestety, zabrakło Ci
          trochę punktów do nagrody.
        </p>
      </div>
    )
  } else if (percentile >= 0) {
    text = (
      <div>
        <h1>Podsumowanie</h1>
        <p>
          Twój wynik jest lepszy lub równy wynikowi {percentile}% uczestników. Zabrakło Ci trochę
          punktów do nagrody.
        </p>
      </div>
    )
  } else {
    text = (
      <div>
        <h1>Nie udało się odczytać Twojego wyniku</h1>
      </div>
    )
  }

  return (
    <div
      className={clsx("Sprint", {
        vertical: PlayArea.isVertical(),
        horizontal: !PlayArea.isVertical(),
      })}
    >
      <SprintArea slug="project-results" className={clsx("game", "scrollable")}>
        <Card className="ProjectResults">{text}</Card>
      </SprintArea>
      {CONFIG.SHOW_CONSENT && <SprintCookieConsent />}
    </div>
  )
}
