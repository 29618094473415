import React from "react"
import PropTypes from "prop-types"

import ExerciseComponent from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import Sounds from "lib/Sounds"
import InstructionCard from "components/InstructionCard/InstructionCard"
import Room from "./subcomponents/Room"
import processAnswers from "lib/answers"
import FeedbackCard from "components/FeedbackCard/FeedbackCard"

import "./DoorsExercise.scss"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { defaultTo } from "lodash"
import { DEFAULT_STATES } from "base/ExerciseComponent"

import exerciseImg from "./img/exercise.jpg"
import exerciseVerticalImg from "./img/exercise-vertical.jpg"
import { INSTRUCTION_STEP_TYPES } from "base/subcomponents/ExerciseInstructions"

import { withTranslation } from "react-i18next"

const DEFAULT_TIME_LIMIT_S = 30

const STATES = {
  ...DEFAULT_STATES,
  STARTING: 0,
  QUESTION_ANSWERING: 1,
  QUESTION_ANSWERED: 3,
  QUESTION_CHANGING: 4,
  ALL_QUESTIONS_ANSWERED: 5,
}

class DoorsExercise extends ExerciseComponent {
  static exerciseClass = "DoorsExercise"
  chosenAnswerIds = []
  instruction
  instructionsAvailable = true

  initInstructions(props) {
    const { t, parameters } = props
    const pointsPerKey = parameters["pointsPerQuestion"] ?? 1

    this.instruction = t("doors.game_instruction")
    this.instructions = {
      name: t("doors.name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("doors.instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("doors.instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("doors.instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: t("doors.instruction_points", { count: pointsPerKey }),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    }
  }

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
    }),

    onFinish: PropTypes.func,
  }

  static maxPoints(questions, parameters) {
    let maxPoints = 0
    for (let question of questions) {
      maxPoints += (question.answers.length - 1) * (parameters["pointsPerQuestion"] ?? 1)
    }
    return maxPoints
  }

  constructor(props) {
    super(props)

    const { parameters, questions } = props

    this.state = {
      ...this.state,
      answers: [],
      chosenAnswer: undefined,

      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      feedback: {
        successful: false,
        content: "",
      },

      questionIndex: 0,
      timeLimit: parameters.timePerQuestionSeconds
        ? parameters.timePerQuestionSeconds
        : DEFAULT_TIME_LIMIT_S,
    }

    this.timePerQuestionSeconds = defaultTo(parameters.timePerQuestionSeconds, DEFAULT_TIME_LIMIT_S)
    this.pointsPerKey = parameters["pointsPerQuestion"] ?? 1
    this.maxPoints = DoorsExercise.maxPoints(questions, parameters)
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.QUESTION_ANSWERING],
        },
        questions,
        parameters
      ),
    ]
  }

  renderExercise(state, props) {
    const { questions } = props
    const {
      answers,
      chosenAnswer,
      isCorrectAnswerChosen,
      isIncorrectAnswerChosen,
      feedback,
      questionIndex,
    } = state

    return (
      <>
        <InstructionCard
          visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          countType="Pytanie"
          countCurrent={questionIndex + 1}
          countMax={questions.length}
          mainText={questions[questionIndex].content}
        />

        <AnimatedElement
          className="room-container"
          visible={this.inStates([
            STATES.QUESTION_ANSWERING,
            STATES.QUESTION_ANSWERED,
            STATES.QUESTION_CHANGING,
          ])}
        >
          <Room
            answers={answers}
            chosenAnswer={chosenAnswer}
            questionIndex={questionIndex}
            lights={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
            onKeyChosenAction={this.onAnswerChosenAction}
            isDoorOpen={isCorrectAnswerChosen}
            isDoorShaking={isIncorrectAnswerChosen}
            onDoorClicked={this.onAnswerConfirmed}
          />
        </AnimatedElement>

        <FeedbackCard
          visible={this.inState(STATES.QUESTION_ANSWERED)}
          useDefaultFeedback={false}
          successful={feedback.successful}
          content={feedback.content}
          canClose={true}
          onFinished={this.continue}
        />
      </>
    )
  }

  startGame = () => {
    this.prepareQuestion()
    this.setCurrentState(STATES.QUESTION_ANSWERING)
  }

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING)
  }

  continue = (force = false) => {
    const { isCorrectAnswerChosen } = this.state

    if (isCorrectAnswerChosen || force) {
      this.setCurrentState(STATES.QUESTION_CHANGING)
    } else {
      this.setCurrentState(STATES.QUESTION_ANSWERING)
      return
    }

    const { questionIndex } = this.state
    const { questions } = this.props

    if (questionIndex + 1 < questions.length) {
      setTimeout(() => {
        this.setState(
          {
            questionIndex: questionIndex + 1,
          },
          () => {
            this.prepareQuestion()
            this.setCurrentStateDelayed(STATES.QUESTION_ANSWERING, 500)
          }
        )
      }, 500)
    } else {
      this.finishData = {
        chosenAnswerIds: this.chosenAnswerIds,
      }
      this.setCurrentStateSequence([STATES.ALL_QUESTIONS_ANSWERED, STATES.FINISHING], 500)
    }
  }

  finished = () => {
    const { onFinish } = this.props
    const { points } = this.state

    onFinish({
      points,
      other: {
        chosenAnswerIds: this.chosenAnswerIds,
      },
    })
  }

  timeRanOut = () => {
    this.continue(true)
  }

  onAnswerChosenAction = (chosenAnswer) => () => {
    this.setState((state) => {
      const { answers } = state
      if (state.chosenAnswer !== undefined) {
        answers[state.chosenAnswer.index].chosen = false
      }
      answers[chosenAnswer.index].chosen = true

      return {
        answers,
        chosenAnswer,
        isIncorrectAnswerChosen: false,
      }
    })
  }

  onAnswerConfirmed = () => {
    if (this.state.chosenAnswer) {
      super._answerChosen(this.state.chosenAnswer)

      this.setState(
        (state) => {
          const { answers, chosenAnswer, points } = state
          let pointChange = 0

          this.chosenAnswerIds.push(chosenAnswer.id)

          const isCorrectAnswerChosen = chosenAnswer.correct
          if (isCorrectAnswerChosen) {
            Sounds.success.play()
            for (let answer of answers) {
              if (answer.active) pointChange += this.pointsPerKey
            }
            pointChange -= this.pointsPerKey
          } else {
            Sounds.error.play()
          }

          const isIncorrectAnswerChosen = !isCorrectAnswerChosen

          const feedback = {
            content: chosenAnswer.feedback,
            successful: isCorrectAnswerChosen,
          }

          answers[chosenAnswer.index] = {
            ...answers[chosenAnswer.index],
            active: false,
            chosen: false,
          }

          return {
            points: points + pointChange,
            answers,
            chosenAnswer: undefined,
            isCorrectAnswerChosen,
            isIncorrectAnswerChosen,
            feedback,
          }
        },
        () => {
          this.setCurrentState(STATES.QUESTION_ANSWERED)
        }
      )
    }
  }

  prepareQuestion = () => {
    const { questions } = this.props
    const { questionIndex } = this.state

    const currentAnswers = questions[questionIndex].answers
    super._questionAppeared(questions[questionIndex])

    this.setState({
      chosenAnswer: undefined,
      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      answers: processAnswers(currentAnswers, DoorsExercise.prepareAnswer),
    })
  }

  static prepareAnswer(answer, index) {
    return {
      id: answer["id"],
      index: index,

      content: answer["content"],
      correct: answer["correct"],
      feedback: answer["parameters"]["feedback"],

      active: true,
      chosen: false,
    }
  }
}

export default withTranslation(["common"])(DoorsExercise)
