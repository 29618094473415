import React from "react"
import classNames from "classnames"

import ExerciseComponent from "../../base/ExerciseComponent"
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement"
import InstructionCard from "../../components/InstructionCard"
import Button from "../../components/Button/Button"

import "./VotingExercise.scss"

import LoveSmileImg from "./images/love-smile.svg"
import HugeSmileImg from "./images/huge-smile.svg"
import SmileImg from "./images/smile.svg"
import MehImg from "./images/meh.svg"
import FrownImg from "./images/frown.svg"
import HugeFrownImg from "./images/huge-frown.svg"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { withTranslation } from "react-i18next"

const IMAGES_EXTENDED = {
  6: LoveSmileImg,
  5: HugeSmileImg,
  4: SmileImg,
  3: MehImg,
  2: FrownImg,
  1: HugeFrownImg,
}

const IMAGES = {
  4: HugeSmileImg,
  3: SmileImg,
  2: MehImg,
  1: HugeFrownImg,
}

const STATES = {
  STARTING: 0,
  STARTED: 1,
  FINISHED: -4,
}

class VotingExercise extends ExerciseComponent {
  answersRange = [1, 2, 3, 4]
  images = IMAGES

  constructor(props) {
    super(props)

    const { parameters } = props
    if (parameters.extended) {
      this.answersRange = [1, 2, 3, 4, 5, 6]
      this.images = IMAGES_EXTENDED
    }

    this.state = {
      ...this.state,
      selected: undefined,
    }
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.STARTED],
        },
        questions,
        parameters
      ),
    ]
  }

  componentDidMount() {
    this._questionAppeared(this.props.questions[0])
  }

  render() {
    const { t } = this.props
    const question = this.props.questions[0]
    const { selected } = this.state

    return (
      <>
        <AnimatedElement className="VotingExercise" visible={!this.inState(STATES.FINISHED)}>
          <InstructionCard
            key="instructions"
            visible={!this.inState(STATES.FINISHED)}
            mainText={question.content}
          />
          <VotingExercise.Answers
            answersRange={this.answersRange}
            images={this.images}
            selectedId={selected}
            selectAnswer$={this.selectAnswer$}
          />
          <Button big disabled={selected === undefined} onClick={this.vote}>
            {t("continue")}
          </Button>
        </AnimatedElement>
      </>
    )
  }

  static Answers = ({ answersRange, images, selectedId, selectAnswer$ }) => {
    return (
      <div className={`VotingExercise-Answers count-${answersRange.length}`}>
        {answersRange.map((answerId) => (
          <Button
            key={answerId}
            className={classNames("voting-smiley", `vote-${answerId}-${answersRange.length}`, {
              selected: selectedId === answerId,
            })}
            onClick={selectAnswer$(answerId)}
          >
            <img src={images[answerId]} alt="Vote" />
          </Button>
        ))}
      </div>
    )
  }

  selectAnswer$ = (selected) => () => {
    this.setState({
      selected,
    })
  }

  vote = () => {
    const { selected } = this.state
    const answer = this.props.questions[0].answers[0]

    this.setCurrentState(STATES.FINISHED, this.finished, 1000)
    super._answerChosen(answer, {
      chosenAnswerOther: {
        vote: selected,
      },
    })
  }

  finished = () => {
    this.finish(true, {})
  }
}

export default withTranslation(["common"])(VotingExercise)
