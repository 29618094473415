import React, { useCallback, useMemo, useRef, useState } from "react"
import { Circle, Group, Rect, Star, Text } from "react-konva"
import random from "lodash/random"

import useGameSize, { MAX_NARROWER, MAX_WIDER } from "hooks/screen/useGameSize"
import useHVPosition from "hooks/screen/useHVPosition"
import COLORS from "../../../../enums/colors"
import Konva from "konva"
import ButtonK from "@konva/ui/ButtonK"
import { onClickableHover, onClickableHoverLeave } from "@konva/clickableHover"
import Sounds from "lib/Sounds/Sounds"
import { useTranslation } from "react-i18next"

const ANSWER_HEIGHT = [400, 600]
const DOWN_DISTANCE = [900, 1200]

const CleaningAnswer = ({ answer, answerCount, answerChosen, answerAppeared, order }) => {
  const [isDown, setIsDown] = useState(false)
  const { t } = useTranslation("exercises/cleaning")

  const { height } = useGameSize()
  const percentageHorizontal = useMemo(
    () => 0.1 + (order / (answerCount > 0 ? answerCount - 1 : 1)) * 0.8,
    [order, answerCount]
  )
  const lineHeight = useMemo(() => [random(500, 900), random(600, 1500)], [])
  const { x } = useHVPosition({
    x: [0.1, 0.1],
  })
  const { y: answerY, width: answerWidth, height: answerHeight } = useHVPosition(
    {
      y: ANSWER_HEIGHT.map((h) => -h - 10),
      width: [0.8 * MAX_WIDER, 0.8 * MAX_NARROWER],
      height: ANSWER_HEIGHT,
    },
    false
  )
  const { x: handleLineX, width: handleLineWidth, height: handleLineHeight } = useHVPosition(
    {
      x: [percentageHorizontal * 0.8 * MAX_WIDER, percentageHorizontal * 0.8 * MAX_NARROWER],
      width: [10, 5],
      height: lineHeight,
    },
    false
  )
  const { width: handleSize } = useHVPosition(
    {
      width: [30, 60],
    },
    false
  )
  const { y: downDistance } = useHVPosition(
    {
      y: DOWN_DISTANCE,
    },
    false
  )

  const nodesR = useRef({})
  const tweenR = useRef()

  const move = useCallback(() => {
    answerAppeared()
    Sounds.click.play()
    const { answer: answerNode } = nodesR.current
    const tween = new Konva.Tween({
      node: answerNode,
      duration: 1,
      y: isDown ? 0 : downDistance,
      easing: Konva.Easings.BackEaseInOut,
    })
    setIsDown(!isDown)
    answerNode.moveToTop()
    tween.play()
  }, [isDown, downDistance, answerAppeared])

  const throwOut = useCallback(() => {
    const { card, handle } = nodesR.current
    const tweenCard = new Konva.Tween({
      node: card,
      duration: 1.5,
      rotation: 360,
      scaleX: 0,
      scaleY: 0,

      // y: 2*height,
      easing: Konva.Easings.BackEaseInOut,
    })
    const tweenHandle = new Konva.Tween({
      node: handle,
      duration: 0.5,

      y: 2 * height,
      easing: Konva.Easings.EaseIn,
    })
    tweenCard.play()
    tweenHandle.play()
    answerChosen(answer)
  }, [answer, answerChosen, height])

  return (
    <Group id="answer" ref={(node) => (nodesR.current.answer = node)} x={x} y={0}>
      <Group
        id="answer-card"
        ref={(node) => (nodesR.current.card = node)}
        x={answerWidth / 2}
        y={answerHeight / 2 + answerY}
        offsetX={answerWidth / 2}
        offsetY={answerHeight / 2}
      >
        <Rect
          id="answer-bg"
          width={answerWidth}
          height={answerHeight}
          fill={COLORS.MAIN}
          stroke={COLORS.BRIGHT}
          strokeWidth={answerWidth / 100}
        />
        <Text
          id="answer-text"
          x={0.05 * answerWidth}
          y={0.05 * answerHeight}
          width={0.9 * answerWidth}
          height={0.5 * answerHeight}
          align="center"
          verticalAlign="middle"
          fill={COLORS.WHITE}
          fontFamily={COLORS.FONT}
          text={answer.content}
          fontSize={40}
          lineHeight={1.25}
        />
        <ButtonK
          x={0.05 * answerWidth}
          y={0.65 * answerHeight}
          width={0.4 * answerWidth}
          height={0.3 * answerHeight}
          text={t("throw_out")}
          onClick={throwOut}
        />
        <ButtonK
          x={0.55 * answerWidth}
          y={0.65 * answerHeight}
          width={0.4 * answerWidth}
          height={0.3 * answerHeight}
          text={t("keep")}
          onClick={move}
        />
      </Group>
      <Group id="handle" ref={(node) => (nodesR.current.handle = node)} x={handleLineX} y={0}>
        <Rect
          id="handle-line"
          width={handleLineWidth}
          height={handleLineHeight}
          fill={COLORS.BRIGHT}
        />
        <Handle
          id="handle-ring"
          innerRef={(node) => (nodesR.current.ring = node)}
          order={order}
          x={handleLineWidth / 2}
          y={handleLineHeight + handleSize / 2}
          size={handleSize}
          action={move}
          onMouseEnter={onClickableHover(nodesR.current.ring, tweenR.current, {
            changeFill: false,
          })}
          onMouseLeave={onClickableHoverLeave(nodesR.current.ring, tweenR.current, {
            changeFill: false,
          })}
        />
      </Group>
    </Group>
  )
}

const Handle = ({ order, x, y, size, action, innerRef, ...other }) => {
  const shapeId = order % 5

  switch (shapeId) {
    case 1: {
      return (
        <Star
          id="handle-ring"
          ref={innerRef}
          x={x}
          y={y + size / 4}
          numPoints={5}
          outerRadius={size}
          innerRadius={size / 2}
          stroke={COLORS.CLICKABLE}
          strokeWidth={size / 2.5}
          onClick={action}
          onTap={action}
          {...other}
        />
      )
    }
    case 2: {
      return (
        <Star
          id="handle-ring"
          ref={innerRef}
          x={x}
          y={y + size / 4}
          numPoints={3}
          outerRadius={size}
          innerRadius={size / 2}
          stroke={COLORS.CLICKABLE}
          strokeWidth={size / 2.5}
          onClick={action}
          onTap={action}
          {...other}
        />
      )
    }
    case 3: {
      return (
        <Rect
          id="handle-ring"
          ref={innerRef}
          x={x}
          y={y + size / 4}
          offsetX={size * 0.75}
          offsetY={size * 0.75}
          width={size * 1.5}
          height={size * 1.5}
          stroke={COLORS.CLICKABLE}
          strokeWidth={size / 2.5}
          onClick={action}
          onTap={action}
          {...other}
        />
      )
    }
    case 4: {
      return (
        <Star
          id="handle-ring"
          ref={innerRef}
          x={x}
          y={y + size / 4}
          numPoints={4}
          outerRadius={size}
          innerRadius={size / 2}
          stroke={COLORS.CLICKABLE}
          strokeWidth={size / 2.5}
          onClick={action}
          onTap={action}
          {...other}
        />
      )
    }
    default:
      return (
        <Circle
          id="handle-ring"
          ref={innerRef}
          x={x}
          y={y}
          radius={size / 1.5}
          stroke={COLORS.CLICKABLE}
          strokeWidth={size / 2.5}
          onClick={action}
          onTap={action}
          {...other}
        />
      )
  }
}

export default CleaningAnswer
