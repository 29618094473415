import { MAX_NARROWER, MAX_WIDER } from "hooks/screen/useGameSize"

const CARD_WIDTH = [0.35, 0.55]
export const CARD_SIZE = {
  x: CARD_WIDTH,
  y: [
    (((CARD_WIDTH[0] * 2) / 3) * MAX_WIDER) / MAX_NARROWER,
    (((CARD_WIDTH[1] * 2) / 3) * MAX_NARROWER) / MAX_WIDER,
  ],
}
export const CARD_MARGIN = { x: [0.02, 0.02] }
