import React, { useState } from "react"
import classNames from "classnames"
import SVG from "react-inlinesvg"
import _ from "lodash"

import "./Room.scss"
import ChangingText from "components/ChangingText/ChangingText"

const KEY_IMAGES = [
  require("../img/keys/key-1.svg"),
  require("../img/keys/key-2.svg"),
  require("../img/keys/key-3.svg"),
  require("../img/keys/key-4.svg"),
]

const PATTERN_IMAGES = [
  require("../img/patterns/44.png"),
  require("../img/patterns/6.png"),
  require("../img/patterns/8.png"),
  require("../img/patterns/10.png"),
  require("../img/patterns/23.png"),
  require("../img/patterns/35.png"),
  require("../img/patterns/43.png"),
]

export default function Room({
  questionIndex,
  answers,
  chosenAnswer,
  onKeyChosenAction,
  isDoorOpen,
  isDoorShaking,
  onDoorClicked,
  lights,
}) {
  return (
    <div className="Room">
      <Darkness active={!lights} />
      <Wall questionIndex={questionIndex}>
        <Door
          isDoorOpen={isDoorOpen}
          isDoorShaking={isDoorShaking}
          canOpenDoor={!!chosenAnswer}
          onDoorClicked={onDoorClicked}
        />
        <Keys onKeyChosenAction={onKeyChosenAction} answers={answers} />
      </Wall>
      <Floor>
        <Answer chosenAnswer={chosenAnswer} />
      </Floor>
    </div>
  )
}

function Darkness({ active }) {
  return <div className={classNames("Darkness", { active: active })} />
}

function Wall({ children, questionIndex }) {
  const [patterns] = useState(_.shuffle(PATTERN_IMAGES))

  return (
    <div className="Wall">
      <div
        className="wallpaper"
        style={{ backgroundImage: `url(${patterns[questionIndex % patterns.length]})` }}
      />
      {children}
    </div>
  )
}

function Door({ canOpenDoor, isDoorOpen, isDoorShaking, onDoorClicked }) {
  return (
    <div className="Door">
      <div
        className={classNames("door", {
          open: isDoorOpen,
          active: !isDoorOpen && canOpenDoor,
          shaking: isDoorShaking,
        })}
        onClick={onDoorClicked}
      >
        <div className="handle" />
      </div>
      <div className="inside">
        <div className="sky">
          <div className="sun" />
        </div>
      </div>
    </div>
  )
}

function Keys({ answers, onKeyChosenAction }) {
  return (
    <div className="Keys">
      {answers.map((answer, id) => {
        return <Key key={id} answer={answer} onClick={onKeyChosenAction(answer)} imgId={id} />
      })}
    </div>
  )
}

function Key({ answer, imgId, onClick }) {
  function clicked() {
    if (answer.active && !answer.chosen) {
      onClick()
    }
  }

  return (
    <div
      className={classNames("Key", { chosen: answer.chosen, active: answer.active })}
      onClick={clicked}
    >
      <SVG src={KEY_IMAGES[imgId]} />
    </div>
  )
}

function Floor({ children }) {
  return <div className="Floor">{children}</div>
}

function Answer({ chosenAnswer }) {
  let content = ""
  if (chosenAnswer) {
    content = chosenAnswer.content
  }

  return (
    <div className="Answer">
      <ChangingText content={content} />
    </div>
  )
}
