import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-xhr-backend"
import CONFIG from "./config"
// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: CONFIG.LANGUAGE,
    // preload: ["pl"],
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      queryStringParams: { v: "1.5.10" },
    },
  })

// Versions:
// 1.0.0 initial translations
// 1.0.3 added elevator and shooting
// 1.1.0 added cauldron
// 1.1.4 added train
// 1.1.5 added balloons
// 1.2.1 added cleaning
// 1.3.0 added puzzle and true_false
// 1.3.3 tiles and doors instructions for custom points
// 1.4.0 more english translations
// 1.5.0 start merging translations to one file

export default i18n
