import Module from "@exercises/modules/Module"
import { defaultTo } from "lodash"

export default class StatsModule extends Module {
  hasEvents = true

  timestamp
  timestamps = {}
  resetTimestampInStates = []
  resetTimestampOnNewQuestion = true
  resetTimestampOnEventOccurrence = false

  currentQuestion

  constructor(parameters = {}, questions = [], exerciseParameters = {}) {
    super(parameters, questions, exerciseParameters)

    this.resetTimestampInStates = defaultTo(
      parameters["resetTimestampInStates"],
      this.resetTimestampInStates
    )
    this.resetTimestampOnNewQuestion = defaultTo(
      parameters["resetTimestampOnNewQuestion"],
      this.resetTimestampOnNewQuestion
    )
    this.resetTimestampOnEventOccurrence = defaultTo(
      parameters["resetTimestampOnEventOccurrence"],
      this.resetTimestampOnEventOccurrence
    )
    this.eventsRecorded = []
  }

  stateActions() {
    let actions = super.stateActions()

    for (const state of this.resetTimestampInStates) {
      actions.push({
        state,
        action: () => this.resetTimestamp(),
      })
    }

    return actions
  }

  resetTimestamp = (id) => {
    if (id) this.timestamps[id] = Date.now()
    else this.timestamp = Date.now()
  }

  questionAppeared(question) {
    super.questionAppeared(question)

    this.resetTimestamp()
    this.currentQuestion = question
  }

  answerAppeared(answer) {
    this.resetTimestamp()
  }

  addEvent(event) {
    if (this.resetTimestampOnEventOccurrence) {
      this.resetTimestamp()
    }

    event["occurred"] = Date.now()
    this.eventsRecorded.push(event)
  }

  createEvent(type, data) {
    return {
      type,
      ...data,
    }
  }

  getEvents() {
    return this.eventsRecorded
  }
}
