import React, { Component } from "react"

import "./App.scss"
import "text-security/dist/text-security.css"

import Sprint from "./Sprint"
import { Route, BrowserRouter, Switch } from "react-router-dom"
import ProjectResults from "../components/ProjectResults"

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path={`/results`} component={ProjectResults} />
            <Route path={`/:instanceName?/:sessionId?`} component={Sprint} />
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
