import React, { useCallback, useMemo, useRef, useState } from "react"
import { Circle, Group, Text } from "react-konva"
import { random } from "lodash"

import useHVPosition from "hooks/screen/useHVPosition"
import COLORS from "../../../enums/colors"
import { useEffectOnce } from "react-use"
import Konva from "konva"
import useImage from "use-image"
import TEXT_SIZES from "../../../enums/text_size"
import useGameSize from "../../../hooks/screen/useGameSize"
import Explosion from "../../../@konva/effects/Explosion"

const PATTERNS = [
  require("images/patterns/6.png"),
  require("images/patterns/8.png"),
  require("images/patterns/10.png"),
  require("images/patterns/23.png"),
  require("images/patterns/35.png"),
  require("images/patterns/43.png"),
  require("images/patterns/44.png"),
]

const [MIN_SIZE, MAX_SIZE] = [0.1, 0.12]
const scaleToMargin = (margin, value) => (1 - margin) * value + margin / 2

const Bubble = ({ floatDuration = 10, answer, startX, endX, answerChosen, onFinish }) => {
  const size_narrower = useMemo(() => random(MIN_SIZE, MAX_SIZE, true), [])
  const size_wider = useMemo(() => size_narrower * 1.6, [size_narrower])
  const imageIndex = useMemo(() => random(0, PATTERNS.length - 1), [])

  const [isExploding, setIsExploding] = useState(false)
  const [active, setActive] = useState(true)
  const { isVertical } = useGameSize()
  const { x, y, width: radius } = useHVPosition({
    x: [
      scaleToMargin(2 * size_narrower * 1.3, startX),
      scaleToMargin(2 * size_wider * 1.3, startX),
    ],
    y: [1, 1],
    width: [size_narrower, size_wider],
  })
  const { x: animationEndX, y: animationEndY } = useHVPosition({
    x: [scaleToMargin(size_narrower, endX), scaleToMargin(size_wider, endX)],
    y: [0, 0],
  })

  const [patternImage] = useImage(PATTERNS[imageIndex])

  const nodesR = useRef({
    main: null,
    bubble: null,
    ellipse: null,
    ellipseBackground: null,
    ellipsePattern: null,
    text: null,
  })
  const animationsR = useRef({
    pulsing: null,
    moving: null,
  })

  useEffectOnce(() => {
    animationsR.current.moving = new Konva.Tween({
      node: nodesR.current.main,
      duration: floatDuration,

      x: animationEndX,
      y: animationEndY - radius,

      onFinish: () => onFinish(),
    })
    animationsR.current.moving.play()

    const scaleDuration = random(0.5, 0.8)
    const scaleChange = random(1.05, 1.1, true)

    animationsR.current.pulsing = new Konva.Tween({
      node: nodesR.current.ellipse,
      duration: scaleDuration,
      easing: Konva.Easings.EaseInOut,

      scaleX: scaleChange,
      scaleY: scaleChange,

      onFinish: () => {
        animationsR.current.pulsing.reverse()
      },
      onReset: () => {
        animationsR.current.pulsing.play()
      },
    })

    animationsR.current.pulsing.play()
  })

  const innerAnswerChosen = useCallback(() => {
    if (active) {
      setActive(false)
    } else {
      return
    }

    animationsR.current.pulsing.pause()
    animationsR.current.moving.pause()

    if (answer.correct) {
      setIsExploding(true)

      nodesR.current.ellipsePattern.hide()
      nodesR.current.text.hide()

      new Konva.Tween({
        node: nodesR.current.bubble,
        duration: 1,
        easing: Konva.Easings.BackEaseInOut,

        scaleX: 0.25,
        scaleY: 0.25,

        onFinish: () => setTimeout(onFinish, 1000),
      }).play()

      new Konva.Tween({
        node: nodesR.current.ellipseBackground,
        duration: 0.1,

        stroke: COLORS.SUCCESS,
      }).play()

      new Konva.Tween({
        node: nodesR.current.ellipseBackground,
        duration: 1,
        easing: Konva.Easings.EaseIn,

        radius: 1.3 * radius,
        strokeWidth: 0,
      }).play()
    } else {
      new Konva.Tween({
        node: nodesR.current.bubble,
        duration: 1,
        easing: Konva.Easings.BackEaseIn,

        scaleX: 0,
        scaleY: 0,

        onFinish: onFinish,
      }).play()

      new Konva.Tween({
        node: nodesR.current.ellipseBackground,
        duration: 0.25,

        stroke: COLORS.ERROR,
      }).play()

      new Konva.Tween({
        node: nodesR.current.ellipsePattern,
        duration: 0.25,

        fill: COLORS.ERROR,
      }).play()
    }

    answerChosen()
  }, [answer.correct, radius, active, onFinish, answerChosen])

  return (
    <Group x={x} y={y + radius} ref={(n) => (nodesR.current.main = n)}>
      <Group
        ref={(n) => (nodesR.current.bubble = n)}
        onClick={innerAnswerChosen}
        onTap={innerAnswerChosen}
      >
        <Group
          ref={(n) => (nodesR.current.ellipse = n)}
          onClick={innerAnswerChosen}
          onTap={innerAnswerChosen}
        >
          <Circle
            onClick={innerAnswerChosen}
            onTap={innerAnswerChosen}
            ref={(n) => (nodesR.current.ellipseBackground = n)}
            radius={radius / 2}
            stroke={COLORS.CLICKABLE}
            strokeWidth={radius}
          />
          <Circle
            onClick={innerAnswerChosen}
            onTap={innerAnswerChosen}
            ref={(n) => (nodesR.current.ellipsePattern = n)}
            radius={radius}
            fillPatternImage={patternImage}
            opacity={0.15}
          />
        </Group>
        <Text
          onClick={innerAnswerChosen}
          onTap={innerAnswerChosen}
          ref={(n) => (nodesR.current.text = n)}
          x={0.15 * radius}
          y={0.15 * radius}
          offsetX={radius}
          offsetY={radius}
          width={1.7 * radius}
          height={1.7 * radius}
          align="center"
          verticalAlign="middle"
          fontFamily={COLORS.FONT}
          text={answer.content}
          fill={COLORS.WHITE}
          fontSize={TEXT_SIZES["sm"][!isVertical ? 0 : 1]}
          fontStyle="bold"
        />
      </Group>
      <Explosion active={isExploding} count={12} duration={500} />
    </Group>
  )
}

export default Bubble
