import { useCallback } from "react"
import useEffectOnce from "react-use/lib/useEffectOnce"
import create from "zustand"

import { useAnswers } from "./$answers"
import { NEXT_QUESTION_TYPE } from "../../@exercises/configs/default_config"

const DEFAULT_STATE = {
  index: 0,
  questions: [],
  current: {},
}

export const useQuestionsState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),

  changeQuestion: (index) =>
    set((s) => ({
      index,
      current: s.questions[index],
    })),
}))

export const useQuestions = (config) => {
  const { questions } = config
  const { index, current, changeQuestion, resetState } = useQuestionsState()

  const { answers, all_tries_finished, answerChosen, last_answer, questionChanged } = useAnswers(
    config
  )

  const nextType = config.parameters.questions.next
  const current_count = index + 1
  const all_count = questions?.length

  useEffectOnce(() => {
    resetState({
      index: 0,
      questions: questions,
      current: questions[0],
    })

    return () => resetState()
  })

  const nextQuestion = useCallback(() => {
    let new_index

    switch (nextType) {
      case NEXT_QUESTION_TYPE.IN_ANSWER:
        const nextQuestionIndex = last_answer.parameters.nextQuestionIndex

        new_index = (nextQuestionIndex ?? 0) === 0 ? index + 1 : nextQuestionIndex
        break
      default:
        new_index = index + 1
    }

    if (new_index < 0 || new_index >= questions.length) {
      return false
    } else {
      const new_question = questions[new_index]

      changeQuestion(new_index)
      questionChanged(new_question)
      return true
    }
  }, [
    index,
    questions,
    last_answer.parameters.nextQuestionIndex,
    nextType,
    changeQuestion,
    questionChanged,
  ])

  return {
    current,

    count: {
      current: current_count,
      all: all_count,
    },

    answers: {
      current: answers,
      all_tries_finished,
      answerChosen,
    },

    nextQuestion,
  }
}
