import React, { Component } from "react"
import PropTypes from "prop-types"

import "./css/OverlayMessage.css"
import Card from "./Card"

export default class OverlayMessage extends Component {
  static propTypes = {
    className: PropTypes.string,

    messages: PropTypes.array,
    messageId: PropTypes.number,
    visible: PropTypes.bool,
  }

  static defaultProps = {
    className: "",
    messageId: 0,
    visible: true,
  }

  DOM = null

  render() {
    return (
      <div
        className={"OverlayMessage " + this.props.className}
        onClick={this.props.onClick}
        ref={
          this.props.saveDOM
            ? (element) => {
                this.DOM = element
              }
            : null
        }
      >
        {this.props.visible && (
          <Card>
            <span key={this.props.messageId}>{this.props.messages[this.props.messageId]}</span>
          </Card>
        )}
        {this.props.visible && <div className="backdrop" />}
      </div>
    )
  }
}
