import React from "react"
import _, { defaultTo } from "lodash"

import ExerciseComponent, { DEFAULT_STATES } from "base/ExerciseComponent"

import AnswerCard from "components/AnswerCard/AnswerCard"
import Animations from "lib/Animations"

import InstructionCard from "components/InstructionCard/InstructionCard"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import ChosenAnswerStatsModule from "@exercises/modules/stats/ChosenAnswerStatsModule"

import "./TilesExercise.scss"
import { withTranslation } from "react-i18next"
import { INSTRUCTION_STEP_TYPES } from "../../../base/subcomponents"
import exerciseImg from "../../../screens/RevealExercise/img/exercise.jpg"
import exerciseVerticalImg from "../../../screens/RevealExercise/img/exercise-vertical.jpg"
import exerciseBlurredImg from "../../../screens/RevealExercise/img/exercise-blurred.jpg"
import exerciseBlurredVerticalImg from "../../../screens/RevealExercise/img/exercise-blurred-vertical.jpg"
import FeedbackCard from "../../../components/FeedbackCard"

const ANIMATION_SPEED = 1000

export const DEFAULT_TILES_CORRECT_POINTS = 2

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_HIDING: 4,
}

class TilesExercise extends ExerciseComponent {
  static exerciseClass = "TilesExercise"
  static defaultParameters = {
    timePerQuestionSeconds: 15,
    answersHidden: false,
  }

  static usedModules = [ChosenAnswerStatsModule]

  chosenAnswerIds = []
  timeout = null

  pointsPerQuestion

  static maxPoints(questions, parameters) {
    return questions.length * (parameters["pointsPerQuestion"] ?? DEFAULT_TILES_CORRECT_POINTS)
  }

  initInstructions(props) {
    const { t, parameters } = props
    const { hidePoints } = parameters

    const shouldBlurAnswers = defaultTo(parameters.answersHidden, false)

    const points = parameters["pointsPerQuestion"] ?? DEFAULT_TILES_CORRECT_POINTS

    this.instruction = shouldBlurAnswers
      ? t("tiles.game_instruction_blurred")
      : t("tiles.game_instruction")
    if (parameters["noInstructions"]) {
      return
    }
    this.instructions = {
      name: shouldBlurAnswers ? t("tiles.name_blurred") : t("tiles.name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("tiles.instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: shouldBlurAnswers
          ? t("tiles.instruction_execution_blurred")
          : t("tiles.instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("tiles.instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: hidePoints
          ? t("tiles.instruction_hidden_points")
          : t("tiles.instruction_points", {
              count: points,
            }),
      },
      imageHorizontal: shouldBlurAnswers ? exerciseBlurredImg : exerciseImg,
      imageVertical: shouldBlurAnswers ? exerciseBlurredVerticalImg : exerciseVerticalImg,
    }
  }

  initHints(parameters, t) {
    const shouldBlurAnswers = defaultTo(parameters.answersHidden, false)

    if (shouldBlurAnswers) {
      this.hints = [
        {
          id: "TilesExercise-answers",
          highlightDelayMs: 2000,
          content:
            "Pamiętaj! Odpowiedzi na start są ukryte - kliknij na dowolną z nich, aby ją odkryć. Kliknięcie na odkrytą odpowiedź zaznacza odpowiedź.",
          duration: 3000,
        },
      ]
    }
  }

  constructor(props) {
    super(props)

    Animations.newBurst("correct", "rgb(255, 120, 0)", "rgb(0, 198, 24)")
    Animations.newBurst("incorrect", "rgb(255, 120, 0)", "rgb(255, 13, 0)")

    const { questions, parameters } = props

    this.state = {
      ...this.state,
      chosenAnswer: null,
      playing: true,
      visible: true,
      gameFinished: false,

      unblurredAnswer: undefined,

      showAnswers: true,
      showQuestion: true,
      showFeedback: false,

      questions: _.clone(props.questions),
      currentQuestionIndex: 0,
    }

    this.timePerQuestionSeconds = parameters.timePerQuestionSeconds
    this.maxPoints = TilesExercise.maxPoints(questions, parameters)
    this.pointsPerQuestion = parameters["pointsPerQuestion"] ?? DEFAULT_TILES_CORRECT_POINTS

    this.showNeutralFeedback = parameters["showNeutralFeedback"]

    this.prepareQuestions()
    this.onState(STATES.QUESTION_ANSWERING, this.questionAppeared)
  }

  usedModules(questions, parameters) {
    return [new ChosenAnswerStatsModule({}, questions, parameters)]
  }

  prepareQuestions = () => {
    const { answersHidden } = this.parameters

    for (let question of this.state.questions) {
      question.answers = _.shuffle(question.answers)

      for (let answerIndex in question.answers) {
        if (question.answers.hasOwnProperty(answerIndex)) {
          let answer = question.answers[answerIndex]
          _.extend(answer, {
            neutralFeedback: this.showNeutralFeedback,
            correct: answer.correct,
            active: true,
            visible: true,
            blurred: answersHidden,
            showFeedback: false,
            index: answerIndex,
          })
        }
      }
    }
  }

  renderExercise = (state, props) => {
    const { questions, t } = props
    const { chosenAnswer } = this.state

    return (
      <>
        <AnimatedElement
          visible={!this.inStates([STATES.QUESTION_HIDING])}
          animation={AnimatedElement.AnimationTypes.slideLeft}
          durationMs={ANIMATION_SPEED}
        >
          <InstructionCard
            countType={t("question")}
            countCurrent={this._currentQuestionCount()}
            countMax={questions.length}
            mainText={this._currentQuestionContent()}
          />
        </AnimatedElement>
        <AnimatedElement
          visible={!this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_HIDING])}
        >
          <div id="TilesExercise-answers" className="answers-container">
            {this.renderAnswers()}
          </div>
        </AnimatedElement>
        <FeedbackCard
          key="feedback"
          visible={this.inState(STATES.QUESTION_ANSWERED)}
          onFinished={this.continueGame}
          content={chosenAnswer?.parameters ? chosenAnswer.parameters.feedback : null}
          successful={chosenAnswer?.correct}
          useDefaultFeedback={false}
        />
      </>
    )
  }

  startGame = () => {
    this.setCurrentStateSequence(
      [STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING],
      ANIMATION_SPEED
    )
  }

  renderAnswers = () => {
    return this._getCurrentAnswers().map((answer, index) => {
      return (
        <AnswerCard
          isCorrect={answer.correct}
          isBlurred={answer.blurred}
          answer={answer}
          disabled={!this.inState(STATES.QUESTION_ANSWERING)}
          onClick={this.answerChosen}
          onBlurredClick={this.blurredChosen}
          key={index}
        >
          {answer.content}
        </AnswerCard>
      )
    })
  }

  blurredChosen = (answer) => {
    if (!this.state.playing) {
      return
    }

    this.setState((state) => {
      let { questions, currentQuestionIndex, unblurredAnswer } = state

      if (unblurredAnswer) {
        questions[currentQuestionIndex].answers[unblurredAnswer.index].blurred = true
      }
      unblurredAnswer = answer
      questions[currentQuestionIndex].answers[answer.index].blurred = false

      return {
        questions,
        unblurredAnswer,
      }
    })
  }

  answerChosen = (answer, event) => {
    super._answerChosen(answer)

    if (!this.state.playing) {
      return
    }

    let position = [event.clientX, event.clientY]
    let pointsChange = 0

    this.chosenAnswerIds.push(answer.id)

    if (this.pointsPerQuestion === 0 || answer.correct) {
      pointsChange = this.pointsPerQuestion

      Animations.playBurst("correct", position)
    } else {
      Animations.playBurst("incorrect", position)
    }

    this.setState(
      (state) => {
        return {
          chosenAnswer: answer,
          points: state.points + pointsChange,
        }
      },
      () => {
        this.unBlurCurrentAnswers()
        this.setCurrentState(STATES.QUESTION_ANSWERED)
      }
    )
  }

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERED, this.unBlurCurrentAnswers)
  }

  unBlurCurrentAnswers = () => {
    this.setState((state) => {
      let { questions, currentQuestionIndex } = state
      for (let answer of questions[currentQuestionIndex].answers) {
        answer.blurred = false
      }

      return {
        questions,
      }
    })
  }

  _getActiveQuestionForState = (state = this.state) => {
    return state.questions[state.currentQuestionIndex]
  }

  continueGame = () => {
    let nextStep, nextState
    if (this.isLastQuestionShown()) {
      this.finishData = {
        chosenAnswerIds: this.chosenAnswerIds,
      }

      Animations.reset()
      nextState = [STATES.QUESTION_HIDING, STATES.FINISHING]
    } else {
      nextState = [STATES.QUESTION_HIDING]
      nextStep = this.showNextQuestion
    }

    this.setCurrentStateSequence(nextState, 1000, nextStep, ANIMATION_SPEED)
  }

  isLastQuestionShown = () => {
    return this.state.currentQuestionIndex >= this.state.questions.length - 1
  }

  showNextQuestion = () => {
    this.setState(
      (state) => {
        return {
          currentQuestionIndex: state.currentQuestionIndex + 1,
        }
      },
      () => {
        this.setCurrentStateSequence(
          [STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING],
          ANIMATION_SPEED
        )
      }
    )
  }

  questionAppeared = () => {
    const question = this._getActiveQuestionForState()
    super._questionAppeared(question)
  }

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING)
  }

  _currentQuestionContent = () => {
    return this._getActiveQuestionForState(this.state).content
  }

  _currentQuestionCount = () => {
    return this.state.currentQuestionIndex + 1
  }

  _getCurrentAnswers = () => {
    return this._getActiveQuestionForState(this.state).answers
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    super.componentWillUnmount()
  }
}

export default withTranslation(["common"])(TilesExercise)
