export function isPointInRectangle(point, rectangle) {
  let { x, y, width, height } = rectangle
  if (x === undefined) {
    x = rectangle.left
  }
  if (y === undefined) {
    y = rectangle.top
  }

  return x < point[0] && x + width > point[0] && y < point[1] && y + height > point[1]
}
