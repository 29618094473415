import React from "react"
import clsx from "clsx"
import { ReactComponent as CupIcon } from "./images/cup.svg"
import { ReactComponent as ClockFrameIcon } from "./images/clock-frame.svg"
import { ReactComponent as ClockHandsIcon } from "./images/clock-hands.svg"

import AnimatedElement from "../AnimatedElement/AnimatedElement"

import "./PointsBar.scss"

const PointsBar = ({
  t,
  showPoints,
  showTime,
  currentPoints,
  maxPoints,
  readableTime,
  isTimeRunningOut,
  isTimeRunning,
  visible,
  instruction,
}) => {
  return (
    <AnimatedElement
      className="PointsBar"
      visible={visible}
      animation={AnimatedElement.AnimationTypes.slideLeft}
    >
      <div className="left-filler" />
      <div
        className={clsx("points-bar-body", { "with-clock": showTime }, { "with-cup": showPoints })}
      >
        <div className="points-bar-with-icon">
          {showPoints && (
            <>
              <CupIcon className={"cup-icon"} />
              <div className="points">
                <span className="current-points">{currentPoints}</span>
                {maxPoints && <span className="max-points">{maxPoints}</span>}
              </div>
            </>
          )}
        </div>
        <div className="points-bar-content">{instruction ?? t("instruction")}</div>
        <div
          className={clsx("points-bar-with-icon", {
            clock: showTime,
            "clock-warning": isTimeRunningOut,
            "clock-warning-stopped": isTimeRunningOut & !isTimeRunning,
          })}
        >
          {showTime && (
            <>
              <ClockFrameIcon className="clock-frame" />
              <ClockHandsIcon className="clock-hands" />
              <div className="time">{readableTime}</div>
            </>
          )}
        </div>
      </div>
    </AnimatedElement>
  )
}

export default PointsBar
